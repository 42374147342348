import { APP_URL } from "./appConfig";
import Lock from "./backend/DTO/Lock";
import User from "./backend/DTO/User";
import CreateUser from "./backend/DTO/CreateUser";
import FileUpload from "./backend/DTO/FileUpload";
import type Status from "./backend/DTO/Lock/Status";
import Customer from "./backend/DTO/Customer";
import UserBankRating from "./backend/DTO/UserBankRating";
import FileSaver from "file-saver";
import Event from "./backend/DTO/Event";
import Alert from "./backend/DTO/Alert";

const API_URL = `${APP_URL}/api/`;

const serializeParams = (obj) => {
    let params = [];
    for (let param in obj) {
        if (Array.isArray(obj[param])) {
            obj[param].forEach((elem, index) => {
                params.push(encodeURIComponent(param + `[${index}]`) + '=' + (elem ? encodeURIComponent(elem) : '%00'))
            });
            continue;
        }
        params.push(encodeURIComponent(param) + '=' + (obj[param] ? encodeURIComponent(obj[param]) : '%00'));
    }

    return params.join('&');
}

const sendBackendRequest = (request_method, api_method, parameters = null) => {
    return fetch(API_URL + api_method + (parameters !== null ? '?' + serializeParams(parameters) : ''), {
        method: request_method,
    });
}

export const getUsers = (search: string = null): Promise<User[]> => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('get', 'User.users')
            .then(res => res.json())
            .then(data => {
                resolve(
                    data.map(item => new User(item))
                        .filter((user: User) => {
                            if (search === null) { return true; }
                            else {
                                return user.email?.toLowerCase().includes(search.toLowerCase())
                                    || user.firstName?.toLowerCase().includes(search.toLowerCase())
                                    || user.lastName?.toLowerCase().includes(search.toLowerCase());
                            }
                        })
                        .sort((a: User, b: User) => ((a.lastName + a.firstName) > (b.lastName + b.firstName)) ? 1 : (((b.lastName + b.firstName) > (a.lastName + a.firstName)) ? -1 : 0))
                );
            })
            .catch(err => reject(err));
    });
}

export const saveUser = (user: User): Promise<string> => {
    return new Promise((resolve, reject) => {
        (user.id.trim() !== ''
            ? sendBackendRequest('post', 'User.user', user.toJson())
            : sendBackendRequest('put', 'User.user', user.toJson())
        )
            .then(res => res.json())
            .then(data => {
                if (data['error'] === undefined) { resolve(data); }
                else { reject(data['error']); }
            })
            .catch(err => reject(err));
    });
}

export const saveChildUser = (user: CreateUser): Promise<string> => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('post', 'User.addChildUser', user.toJson())

            .then(res => res.json())
            .then(data => {
                if (data['error'] === undefined) { resolve(data); }
                else { reject(data['error']); }
            })
            .catch(err => reject(err));
    });
}

export const deleteUser = (user: Lock): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        if (user.id.trim() !== '') {
            sendBackendRequest('DELETE', 'User.user', { userId: user.id })
                .then(resp => resolve(resp.ok))
                .catch(err => reject(err))
        } else { resolve(false); }
    });
}

export const getLocks = (search: string = null): Promise<Lock[]> => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('get', 'Lock.locks')
            .then(res => res.json())
            .then(data => resolve(
                data.map(item => new Lock(item))
                    .filter((lock: Lock) => {
                        if (search === null) { return true; }
                        else {
                            return lock.api?.toLowerCase().includes(search.toLowerCase())
                                || lock.name?.toLowerCase().includes(search.toLowerCase())
                                || lock.description?.toLowerCase().includes(search.toLowerCase());
                        }
                    })
                    .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            ))
            .catch(err => reject(err));
    });
};

export const getUserLocks = (user: User): Promise<Lock[]> => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('get', 'Lock.userLocks', { userId: user.id })
            .then(res => res.json())
            .then(data => resolve(
                data.map(item => new Lock(item))
                    .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            ))
            .catch(err => reject(err));
    });
}

export const assignLockToUser = (user: User, lockIds: string[]): Promise => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('post', 'Lock.assingLockToUser', { userId: user.id, lockIds: lockIds })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
}

export const changeLockStatus = (lock: Lock, status: Status): Promise => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('post', 'Lock.lock', {
            LockId: lock.id,
            LockStatus: status.name
        })
            .then(res => res.json())
            .then(data => {
                if (data['error'] === undefined) { resolve(data); }
                else { reject(data['error']); }
            })
            .catch(err => reject(err));
    });
}

export const saveLock = (lock: Lock): Promise<string> => {
    return new Promise((resolve, reject) => {
        (lock.id.trim() !== ''
            ? sendBackendRequest('post', 'Lock.lock', lock.toJson())
            : sendBackendRequest('put', 'Lock.lock', lock.toJson())
        )
            .then(res => res.json())
            .then(data => {
                if (data['error'] === undefined) { resolve(data); }
                else { reject(data['error']); }
            })
            .catch(err => reject(err));
    });
}

export const deleteLock = (lock: Lock): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        if (lock.id.trim() !== '') {
            sendBackendRequest('DELETE', 'Lock.lock', { lockId: lock.id })
                .then(resp => resolve(resp.ok))
                .catch(err => reject(err))
        } else { resolve(false); }
    });
}

export const getLocations = (): Promise<string[]> => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('get', 'Box.GetLocations')
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
};

export const getCustomers = (search: string = null, locations: string[] = null, showEmpty: boolean = false, showLocked: boolean = false, seek: Number = 0, take: Number = 0, orderField: Number = 0, orderType: Number = 0): Promise<Customer[]> => {
    return new Promise((resolve, reject) => {
        let parameters = {};
        if (search) { parameters.Search = search; }
        if (locations && locations.length) { parameters.Locations = locations; }

        if (seek) { parameters.Seek = seek; }
        if (take) { parameters.Take = take; }

        parameters.CustomerOrderField = orderField;
        parameters.OrderType = orderType;
        parameters.ShowEmptyOnly = showEmpty;
        parameters.NoAccessOnly = showLocked;
        sendBackendRequest('get', 'Box.GetCustomers', parameters)
            .then(res => res.json())
            .then(data => resolve(
                data.map(item => new Customer(item))
            ))
            .catch(err => reject(err));
    });
};

export const getCustomersSmall = (search: string = null): Promise<[]> => {
    return new Promise((resolve, reject) => {
        let parameters = {};
        if (search) { parameters.Search = search; }

        sendBackendRequest('get', 'Box.GetCustomersSmall', parameters)
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
};

export const getParentUser = (customer: Customer): Promise<?Object> => {
    return new Promise((resolve, reject) => {
        if (customer.user === 0) { resolve(null); }
        else {
            sendBackendRequest('get', 'Box.GetParentUser', {
                userId: customer.userId
            })
                .then(res => res.json())
                .then(data => resolve(data))
                .catch(err => reject(err));
        }
    });
}

export const setParentUser = (customer: Customer, parentUserId: string = null): Promise<Number> => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('post', 'Box.setParentUser', {
            userId: customer.userId,
            parentId: parentUserId
        })
            .then((response) => {
                if (response.ok) { resolve(parentUserId ? 1 : 0); }
                else { reject(customer.user); }
            })

            .catch(() => { reject(customer.user); });
    });
};

export const exportCustomers = (search: string = null, locations: string[] = null, orderField: Number = 0, orderType: Number = 0): Promise<Response> => {
    let parameters = {};
    if (search) { parameters.Search = search; }
    if (locations && locations.length) { parameters.Locations = locations; }

    parameters.CustomerOrderField = orderField;
    parameters.OrderType = orderType;
    return sendBackendRequest('get', 'Box.ExportCustomers', parameters);
};

export const changeCustomerManualLock = (customer: Customer): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('put', 'Box.setManualLock', {
            userId: customer.userId,
            manualLock: !customer.manualLock
        })
            .then(() => { resolve(!customer.manualLock); })
            .catch(() => { reject(customer.manualLock); });
    });
}

export const laerAccessUser = (userID: string): Promise => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('get', 'LaerAccess.user', {
            userId: userID
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
}

export const laerAccessUpdate = (userID: string, satelCode: string, accessCodes: string[]): Promise => {
    return new Promise((resolve, reject) => {
        laerAccessUser(userID)
            .then(accessCodesResponse => {
                let deletedAccessCodes = [];
                let createdAccessCodes = [];

                accessCodesResponse['accessCodes'].forEach(el => {
                    deletedAccessCodes.push(el['id']);
                });

                accessCodes.forEach(code => { createdAccessCodes.push(code); })

                sendBackendRequest('post', 'Box.update', {
                    userId: userID,
                    lockId: accessCodesResponse['lockId'],
                    satelCode: satelCode ?? accessCodesResponse['satelCode'],
                    deletedAccessCode: deletedAccessCodes?.length > 0 ? deletedAccessCodes[0] : null,
                    createdAccessCode: createdAccessCodes?.length > 0 ? createdAccessCodes[0] : null
                })
                    .then(res => res.json())
                    .then(data => resolve(data))
                    .catch(err => reject(err))
            })
            .catch(err => reject(err));
    });
}

export const userBankRatingHistory = (userID: string): Promise<UserBankRating[]> => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('get', 'UserBankRating.history', {
            userId: userID
        })
            .then(res => res.json())
            .then(data => resolve(data.map(item => new UserBankRating(item))))
            .catch(err => reject(err));
    });
}

export const userBankRatingUpdate = (userID: string, bankRating: Number, performedUserName: string, performedUserEmail: string): Promise => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('post', 'UserBankRating.update', {
            UserId: userID,
            Rating: bankRating,
            PerformedUserName: performedUserName,
            PerformedUserEmail: performedUserEmail,
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
}

export const getFileUploads = (seek, take): Promise<FileUpload[]> => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('get', 'store365.getuploads', {
            seek: seek,
            take: take
        })
            .then(res => res.json())
            .then(data => resolve(data.map(item => new FileUpload(item))))

    });
}

export const downloadFile = (fileId: String, fileName: String) => {
    FileSaver.saveAs(
        APP_URL + "/api/store365.getFile?storyHistoryId=" + fileId,
        fileName + ".zip"
    );
}

export const resetLockJob = (lockId: String) =>{
    return new Promise((resolve, reject) => {
        sendBackendRequest('post', 'Lock.ResetLockJob', {
            LockId: lockId
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
}

export const resetUserPasword = (userId: String) =>{
    return new Promise((resolve, reject) => {
        sendBackendRequest('post', 'User.ResetUserPassword', {
            userId: userId
        })
            .then(res => res.json())
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
}

export const getEvents = (unitName, errorType, from, to, offset, count) => {
    return new Promise((resolve, reject) => {
        let parameters = {};
        
        parameters.UnitName = unitName;
        parameters.ErrorTypes = errorType;
        parameters.Offset = offset;
        parameters.Count = count;
        parameters.From = from;
        parameters.To = to;
        sendBackendRequest('get', 'Events.events', parameters)
            .then(res => res.json())
            .then(data => resolve(
                data.map(item => new Event(item))
            ))
            .catch(err => reject(err));
    });
};

export const getActiveAlerts = () => {
    return new Promise((resolve, reject) => {
        sendBackendRequest('get', 'Alerts.activeAlerts')
            .then(res => res.json())
            .then(data => resolve(
                data.map(item => new Alert(item))
            ))
            .catch(err => reject(err));
    });
};

export const getAlerts = (alertTypes, from, to) => {
    return new Promise((resolve, reject) => {
        let parameters = {};
        
        parameters.AlertTypes = alertTypes;
        parameters.From = from;
        parameters.To = to;
        sendBackendRequest('get', 'Alerts.alerts', parameters)
            .then(res => res.json())
            .then(data => resolve(     {
                first : data.map(item => new Alert(item)),
                second:  data.map(item => new Alert(item))
            }           
               
            ))
            .catch(err => reject(err));
    });
};

export default sendBackendRequest;