import React from 'react';
import { useEffect, useState } from "react";
import { AuthenticatedLayout } from "../../layouts/AuthenticatedLayout";
import ReactApexChart from "react-apexcharts";
import { getAlerts } from "../../backend";
import { FormattedMessage, useIntl } from "react-intl";
import { FormControl, InputLabel, Select, OutlinedInput, Box, Chip, MenuItem, useTheme, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function List() {

  const theme = useTheme();

  var initialOptions = {
    chart: {
      toolbar: {
        show: false
      },
      id: 'area-datetime',
      type: 'area',
      height: 350,
      zoom: {
        autoScaleYaxis: true
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
      style: 'hollow',
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      }
    },
    xaxis : {
      type: 'datetime',
      tickAmount: 6,
    },
    stroke: {
      curve: 'smooth',
      lineCap: 'round',
      width: 0.5
    }
  }

  const [options, setOptions] = useState(initialOptions);


  const intl = useIntl();

  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState([]);
  const [alertList, setAlertList] = useState([]);


  /*eslint no-extend-native: ["error", { "exceptions": ["Object"] }]*/
  Date.prototype.addDays = function (days) { // eslint-disable-line
    this.setDate(this.getDate() + days); // eslint-disable-line
    return this; // eslint-disable-line
  }; // eslint-disable-line

  useEffect(() => {


    loadAlerts()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function loadAlerts(types, from, to) {

    setLoading(true);

    getAlerts(types, from, to).then((result) => {

      setAlertList(result.first);

      var alers = result.second;

      setOptions(initialOptions);

      const series = [];

      var colors = ["#BB00FF", "#0021FF", "#FF7B00", "#00CBFF", "#47FF5F", "#FF000C"]

      for (var alertType = 0; alertType < 6; alertType++) {
        var data = getGraphDataForAlertType(alers, alertType);
        var name = "All alerts";

        if (alertType < 5) {
          name = intl.formatMessage({
            id: "alert_type_" + alertType,
          });
        }

        var seriesData = {
          name: name.replace("{object_name}", ""),
          data: data,
          color: colors[alertType]
        };

        if(data.length > 2 ){
          series.push(seriesData);
        }
      }

      setSeries(series);

    }).finally(() => {
      setLoading(false)
    });
  }

  function getGraphDataForAlertType(alerts, alertType) {


    var filteredAlerts = alertType < 5 ? alerts.filter((a) => a.alertType === alertType) : alerts;
    var setDates = filteredAlerts.map(a =>
      [
        a.setDateTime.getTime(),
        1
      ]
    );

    var resetDates = filteredAlerts.map(a =>
      [
        a.resetDateTime.getTime(),
        -1
      ]
    );

    if(filteredAlerts.length <= 0) return [];

    var dateRange = getDates(
      new Date(alerts.sort((a, b) => a.setDateTime - b.setDateTime)[0].setDateTime).addDays(-1),
      new Date(alerts.sort((a, b) => b.setDateTime - a.setDateTime)[0].setDateTime).addDays(1)
    );

    var allDate = [...setDates, ...resetDates].sort((a, b) => {
      return a[0] - b[0];
    });

    var data = allDate.reduce(
      (acc, currentValue) => {
        var accLastVal = acc[acc.length - 1][1];
        var currentVal = currentValue[1];
        var time = currentValue[0];
        var newEl = [time, accLastVal + currentVal];
        acc.push(newEl);
        return acc;
      },
      [[dateRange[0].getTime(), 0]],
    );

    return data
  }

  function getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = currentDate.addDays(1);
    }
    return dateArray;
  }

  const getAlertName = (alert) => {

    var alertName = intl.formatMessage({
      id: "alert_type_" + alert.alertType,
    });

    alertName = alertName.replace("{object_name}", alert.objectToRetry);

    return alertName;
  }

  const getAlertNameByType = (alertType) => {

    if (alertType === 5) return "All";

    var alertName = intl.formatMessage({
      id: "alert_type_" + alertType,
    });

    alertName = alertName.replace("{object_name}", "");

    return alertName;
  }

  const [selectedAlertyType, setSelectedAlertType] = useState([]);

  const handleAlertTypesChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedAlertType(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getAlertTypeStyles(name, selectedTypeNames, theme) {
    return {
      fontWeight: selectedTypeNames.includes(name)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
  }

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);


  const onSearchClick = () => {

    loadAlerts(selectedAlertyType.join(), fromDate, toDate);

  }

  return (

    <AuthenticatedLayout>

        <div>
          <FormControl sx={{ m: 1, width: 'auto', minWidth: 250 }}>
            <InputLabel id="error-type-chip-label">Type</InputLabel>
            <Select
              labelId="error-type-chip-label"
              id="error-type-chip"
              multiple
              className='type-selector'
              value={selectedAlertyType}
              onChange={handleAlertTypesChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={getAlertNameByType(value)} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {Array.from(Array(6).keys()).map((alertType) => (
                <MenuItem
                  key={alertType}
                  value={alertType}
                  style={getAlertTypeStyles(getAlertNameByType(alertType), getAlertNameByType(alertType), theme)}
                >
                  {getAlertNameByType(alertType)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl sx={{ m: 1, width: 'auto', minWidth: 250 }}>
              <DatePicker
                label="From"
                onChange={(v) => {
                  if (v) {
                    var date = new Date(v.toString());
                    date = date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                    setFromDate((new Date(date)).toISOString());
                  } else {
                    setFromDate(v?.toString());
                  }
                }}
              />
            </FormControl>
            <FormControl sx={{ m: 1, width: 'auto', minWidth: 250 }}>
              <DatePicker
                label="To"
                onChange={(v) => {
                  if (v) {
                    var date = new Date(v.toString());
                    date = date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                    setToDate((new Date(date)).toISOString());
                  } else {
                    setToDate(null)
                  }
                }}
              />
            </FormControl>
          </LocalizationProvider>
          <FormControl sx={{ m: 1, width: 'auto', minWidth: 250 }}>
            <Button className="search-alert-btn" onClick={onSearchClick} color="inherit" variant="contained">Search</Button></FormControl>
        </div>

        {loading ? (
        <div className="align-content-center text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden"><FormattedMessage id="loading" /></span>
          </div>
        </div>
      ) : (<div>
        <ReactApexChart options={options} series={series} type="area" height={600} />

        <div className="table-box mt-8">
          <table className="table">
            <thead className='border-top-1 border-bottom-1'>
              <tr>
                <th style={{ width: '250px' }}><i><FormattedMessage id="alert_name" /></i></th>
                <th style={{ width: '350px' }}><i><FormattedMessage id="alert_set_date" /></i></th>
                <th style={{ width: '50px' }}><i><FormattedMessage id="alert_retry_count" /></i></th>
              </tr>
            </thead>
            <tbody>
              {

                alertList.map((alert, index) =>
                  <tr key={index}>
                    <td>
                      {
                        getAlertName(alert)
                      }
                    </td>
                    <td>
                      {
                        alert.setDateTime?.toLocaleString()
                      }
                    </td>
                    <td>
                      {alert.retryCount ?? ""}
                    </td>
                  </tr>

                )}
            </tbody>
          </table>
        </div>
      </div>)}



    </AuthenticatedLayout>
  );
}

export { List };